import React from 'react';
import PropTypes from 'prop-types';
import styles from './box.module.scss';

const Box = ({ children, fullColumn }) => {
  const classes = [styles.row + ' '];
  if (fullColumn) classes.push(' ' + styles.fullColumnRow);
  return (
    <div className={styles.container}>
      <div className={classes}>{children}</div>
    </div>
  );
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
  fullColumn: PropTypes.bool,
};

export default Box;

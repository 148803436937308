import React from 'react';
import PropTypes from 'prop-types';
import styles from './homepage-portfolio.module.scss';
import Dots from 'components/addons/general-dots';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const HomepagePortfolio = ({ teaser }) => (
  <div>
    <h2 className={styles.attractor}>Portfolio</h2>
    <div className={styles.row}>
      <div className={styles.column}>
        <Link to="/portfolio/">
          <Img
            className={styles.portfolioTeaser}
            fixed={teaser ? teaser.childImageSharp.fixed : {}}
            alt="Meaningless Reversed Triangle of Trust"
          />
        </Link>
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.column}>
        <Dots />
        <div className={styles.portfolioCopy}>
          <h4>There{'\''}s always more to do.</h4>
          <p>
            It{'\''}s easy to just stick with whatever you already have. It may
            even be prudent to do so. Will it ever take you anywhere else
            though?
          </p>
          <Link to="/portfolio/" className={styles.buttonMore}>
            SEE MORE
          </Link>
        </div>
      </div>
    </div>
  </div>
);

HomepagePortfolio.propTypes = {
  teaser: PropTypes.object.isRequired,
};

export default HomepagePortfolio;

import React from 'react';
import PropTypes from 'prop-types';
import Homepage from 'components/layout/homepage';
import Whoami from 'components/addons/homepage-whoami';
import Blog from 'components/addons/homepage-blog';
import Portfolio from 'components/addons/homepage-portfolio';
import Box from 'components/box/box';
import Column from 'components/box/boxColumn';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

const Index = ({ data }) => {
  const fullColumn = true;
  return (
    <Homepage mainBg={data.heroImage}>
      <Helmet
        meta={[
          {
            name: 'description',
            content:
              'I\'m a UX / UI designer who likes to focus on long-term solutions around fast delivery. I sometimes write so you\'ll find anything from reviews to fantasy stories here. Welcome to my personal space!',
          },
          {
            name: 'keywords',
            content:
              'ui / ux, ui, ux, design, writing, code, opinions, pop, culture, technology, stuff, sci-fi, reviews, freelance, designer, hire, corporate designer',
          },
        ]}
      />
      <Whoami items={data.homeJson.quotes} />
      <Box fullColumn={fullColumn}>
        <Column fullColumn={fullColumn}>
          <Blog posts={data.allMarkdownRemark} />
        </Column>
        <Column fullColumn={fullColumn}>
          <Portfolio teaser={data.headerImage} />
        </Column>
      </Box>
    </Homepage>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "images/portfolio_teaser.jpg" }) {
      childImageSharp {
        fixed(width: 550, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    heroImage: file(
      relativePath: { eq: "images/shot-by-cerqueira-334068-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homeJson {
      quotes {
        person
        link
        copy
        image {
          childImageSharp {
            fixed(height: 80, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(blog)/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            image {
              childImageSharp {
                fluid(maxHeight: 750, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date
            title
            category
          }
          excerpt(pruneLength: 280)
          fields {
            slug
          }
        }
      }
    }
  }
`;

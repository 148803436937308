import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styles from './homepage-blog.module.scss';
import SVG from 'react-svg';
import Img from 'gatsby-image';

const HomepageBlog = ({ posts }) => (
  <div className={styles.postsWrap}>
    <h2 className={styles.attractor}>Blog</h2>
    {posts.edges.map(({ node }, index) => (
      <Link to={node.fields.slug} className={styles.readMore} key={index}>
        <article className={styles.post}>
          <header className={styles.postHeader}>
            <div className={styles.postImage}>
              <Img
                className={styles.postImageInner}
                fluid={
                  node.frontmatter.image
                    ? node.frontmatter.image.childImageSharp.fluid
                    : {}
                }
                alt={node.frontmatter.title}
              />
              <div className={styles.imagecover} />
              <h3>{node.frontmatter.title}</h3>
              <span>{node.frontmatter.category}</span>
              <div className={[styles.frame, styles.frameStyle].join(' ')} />
            </div>
          </header>
          <p className={styles.copy}>{node.excerpt}</p>
          <SVG className={styles.arrow} src={'/icons/arrow_right.svg'} />
        </article>
      </Link>
    ))}
    <Link to="/blog/" className={styles.buttonMore}>
      READ MORE
    </Link>
  </div>
);

HomepageBlog.propTypes = {
  posts: PropTypes.object,
};

export default HomepageBlog;

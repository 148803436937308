import React from 'react';
import PropTypes from 'prop-types';
import Dots from './general-dots';
import styles from './homepage-whoami.module.scss';
import SVG from 'react-svg';
import Quotes from './general-quotes';

const HomepageWhoami = ({ items }) => (
  <div className={styles.container}>
    <div className={styles.row}>
      <div className={styles.column35}>
        <h2 className={styles.attractor}>Who am I</h2>
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.column35}>
        <SVG
          className={styles.leadershipTriangleWrap}
          svgClassName={styles.leadershipTriangle}
          src={'/icons/leadership_triangle.svg'}
        />
        <div className={styles.opinions}>
          <Quotes data={items} />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.explainer}>
          <Dots />
          <ol className={styles.explainers}>
            <li>
              <h3>Leadership</h3>
              <p>
                I believe that leadership is not a title but a choice. The only
                way to be a good leader is to inspire others with your actions
                and dedication to follow autonomously. I choose to be a leader,
                not a task issuer.
              </p>
            </li>
            <li>
              <h3>UX / UI</h3>
              <p>
                The design is a visual expression of everything that we feel we
                want to put out there. Sometimes it needs to be professional and
                cold or other times it needs to call your artsy self. I believe
                that it needs to be YOU.
              </p>
            </li>
            <li>
              <h3>Writing</h3>
              <p>
                I really enjoy writing but I would never call myself a writer. I
                think however that written stories do have some magic in them
                that no other form of art can convey. It allows me to build
                worlds I{'\''}d never show otherwise.
              </p>
            </li>
            <li>
              <h3>Glue that holds it all together</h3>
              <p>
                I suppose almost everyone has some sort of compass in their
                lives. That something that drives us and keeps us honest. For
                me, it{'\''}s my family. I design, I code, I write but I would
                never move forward without my family.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
);

HomepageWhoami.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HomepageWhoami;

import React from 'react';
import PropTypes from 'prop-types';
import Head from 'components/head';
import Helmet from 'react-helmet';
import Header from 'components/header';
import Dots from 'components/addons/general-dots';
import Footer from 'components/footer';
import styles from './homepage.module.scss';
import SVG from 'react-svg';
import Img from 'gatsby-image';

const Homepage = ({ children, mainBg }) => (
  <div className={styles.root}>
    <Helmet>
      <body className={styles.dark} />
    </Helmet>
    <Head />
    <Header />
    <div className={styles.homepagehero}>
      <div className={styles.hompageherobg}>
        <Img
          fluid={mainBg ? mainBg.childImageSharp.fluid : {}}
          alt="Vastness of space"
          className={styles.hompageherobgWrap}
        />
      </div>
      <Dots />
      <div className={styles.fadeout} />
      <div className={styles.container}>
        <div className={styles.welcome}>
          <SVG svgClassName={styles.logobg} src={'/icons/triangle.svg'} />
          <SVG svgClassName={styles.logo} src={'/icons/uncut_logo.svg'} />
          <h1>Hello</h1>
        </div>
        <div className={styles.bottom}>
          <SVG svgClassName={styles.planets} src={'/icons/planets.svg'} />
          <span className={styles.attractor}>The world awaits.</span>
        </div>
        <div className={styles.mouse}>
          <span></span>
        </div>
      </div>
    </div>
    {children}
    <Footer />
  </div>
);

Homepage.propTypes = {
  children: PropTypes.node.isRequired,
  mainBg: PropTypes.object.isRequired,
};

export default Homepage;

import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import styles from './general-quotes.module.scss';
import Img from 'gatsby-image';

const Quotes = ({ data }) => (
  <Carousel
    autoplay={true}
    autoplayInterval={3550}
    transitionMode={'fade'}
    wrapAround
    heightMode={'max'}
    withoutControls={true}
  >
    {data.map((item, i) => (
      <div className={styles.opinion} key={i}>
        <div className={styles.image}>
          <Img
            fixed={item.image ? item.image.childImageSharp.fixed : {}}
            alt={item.person}
          />
        </div>
        <a href={item.link} target="_blank" rel="noopener noreferrer">
          <blockquote className={styles.quote}>
            <p>
              {'"'}
              {item.copy}
              {'"'}
            </p>
            <footer>
              &mdash; <cite>{item.person}</cite>
            </footer>
          </blockquote>
        </a>
      </div>
    ))}
  </Carousel>
);

Quotes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Quotes;
